import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import { Zoom, Fade } from 'react-reveal'

import Layout from '../components/common/Layout'
import Hero1 from '../components/common/Hero1'
import Interstitial from '../components/2019/components/Interstitial'
import Newsletter from '../components/common/Newsletter'
import Tickets from '../components/2019/components/Tickets'
import { DefaultPlayer as Video } from 'react-html5video'

import Wildcard from '../components/common/Wildcard'

import DevDays from '../components/2019/components/DevDays'

import { Container, Row, Col } from 'reactstrap'
import Section from '../components/common/Section'
import { Element as ScrElement, Link as ScrLink } from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as ServiceIcon from '../components/common/ServiceIcon'
import bg4 from '../components/2020/media/bg4.svg'
import bg5 from '../components/2020/media/bg4.svg'

import 'react-html5video/dist/styles.css'

import {
  faCocktail,
  faGuitar,
  faCode,
  faCouch,
  faHashtag,
  faCalendar,
  faTshirt,
  faUtensils,
  faHandshake,
  faChild,
} from '@fortawesome/free-solid-svg-icons'

import { faSlideshare } from '@fortawesome/free-brands-svg-icons'
import CtaBlock from '../components/common/CtaBlock'
import { ContributionSummary } from '../components/2020/ContributionSummary'

export default ({ data }) => {

  const [clientLoaded, setClientLoaded] = useState(false)
  useEffect(() => {
    setClientLoaded(true)
  }, [])

  const slideshowImages = data.images2018.edges.map(e => ({
    src: e.node.recap_image_url,
    key: e.node.recap_image_url,
    altText: e.node.context ? e.node.context.custom.alt : '',
    caption: e.node.context ? e.node.context.custom.caption : '',
    publicId: e.node.public_id,
  }))

  const testimonials = data.testimonials.edges.map(e => e.node)
  const highlights = data.speaker_highlights.edges
    .map(e => ({
      name: e.node.speaker.map(s => s.name).join(','),
      content: e.node.highlights.highlights,
      key: e.node.highlights.id,
    }))
    .concat(testimonials)

  return (
    <Layout>
      <Hero1
        section="section section--first cta cta--darken cta--tertiary"
        style={{ overflow: "hidden" }}
        dynamicBackground
        title={<h1 className="display-one">
          decoding
          <br /> <span className="outline  inverted">the future</span>
        </h1>
        }
        subtitle={<h5 className="big color--primary">Spring 2021 in Faro</h5>}
      >
        <Row>
          <Col lg={6}>
            <Fade left distance="20px">
              <h4 className="mt-0">Dear developers and tech enthusiasts from around the world,</h4>
              <div
                dangerouslySetInnerHTML={{ __html: data.intro1.html }}
              />
            </Fade>
          </Col>
          <Col lg={6}>
            <Fade right distance="20px">
              <div dangerouslySetInnerHTML={{ __html: data.intro2.html }} />
            </Fade>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            {clientLoaded && <Video height={300}
              poster="https://res.cloudinary.com/turbinekreuzberg/image/upload/t_maxeco/v1559136636/devday19/faro/168-P1210110.jpg"
            >
              <source src="https://res.cloudinary.com/turbinekreuzberg/video/upload/v1579109720/devday19/DEV_DAY_2019_Inspiring_the_Future_of_Development.webm" type="" />
            </Video>}
          </Col>
        </Row>
      </Hero1>

      <Section>
        <Newsletter />
      </Section>

      <Interstitial
        title={<h5>1 day of workshops &amp; 2 talk stages</h5>}
        text={
          <p>
            <span>Where will 2021 take </span>{' '}
            <span className="color--primary">software development<span style={{ color: "white" }}>?</span></span>
            <br className="hidden-sm-down" />
            Learn about the {' '}
            <span className="color--primary">latest technologies</span>{' '}
            <br className="hidden-sm-down" />
            to boost your coding career.
          </p>
        }
      />


      {/*<Section>
        <Fade duration={500}>
          <h2 className="page-title">Tickets</h2>

          <h4>ticket sales ends on May 21st, 10am</h4>

          <Tickets
            eventbrite={{ berlin: '52604528561', faro: '55693362346' }}
          />
        </Fade>
      </Section>*/}

      <Section>
        <h2 className="mb-5">What you get</h2>
        <ServiceIcon.ServiceIconList>
          <ServiceIcon.ServiceIcon
            icon={faSlideshare}
            size={6}
            title="10 interactive coding and tech-related talks with code samples and demos"
          />
          {/*covering Elasticsearch, Decentralized Apps, Edge Computing and Event Driven Architecture, Machine Learning, Hexagonal Architecture*/}
          <ServiceIcon.ServiceIcon
            icon={faCode}
            title="1 day of workshops to learn a new tech stack or concept"
          />

          <ServiceIcon.ServiceIcon
            icon={faCouch}
            title="2 panel discussions"
          />
          <ServiceIcon.ServiceIcon
            icon={faCocktail}
            title="1 wild after party featuring local DJs and artists"
          />

          <ServiceIcon.ServiceIcon
            icon={faTshirt}
            title="1 unique DEVDAY'20 shirt"
          />
          <ServiceIcon.ServiceIcon
            icon={faChild}
            title="tech-activities for the kids"
          />


          <ServiceIcon.ServiceIcon
            icon={faUtensils}
            title="Drinks, breakfast snacks and lunch buffet (veggie/vegan/local)"
          />


          <ServiceIcon.ServiceIcon
            icon={faHandshake}
            title="Networking with tech-minded people from the Algarve, Portugal and abroad"
          />

        </ServiceIcon.ServiceIconList>
      </Section>


      {/*<Section section="cta cta--darken cta--tertiary" bg={shape06}>
        <h2>Venues</h2>
        <Row>
          <Col lg={6}>
            <Venue
              className="d-block"
              name="Workshop Day"
              video=""
              address={`Turbine Kreuzberg,
                Rua Mouzinho de Albuquerque 1A,
                8000-397 Faro, Portugal`}
              location={{
                latitude: 37.0181094,
                longitude: -7.9306031,
              }}
            />
          </Col>
          <Col md={6}>
            <Venue
              className="d-block"
              name="Conference Day"
              video=""
              address={`Turbine Kreuzberg,
              Rua Mouzinho de Albuquerque 1A,
              8000-397 Faro, Portugal`}

              location={{
                latitude: 37.0181094,
                longitude: -7.9306031,
              }}
            />
          </Col>
        </Row>
            </Section>*/}





      <CtaBlock backgroundColor="tertiary">


        <Link to="/call-for-speakers" className="btn btn--primary">
          <span className="big">
            Give a talk at
            DEVDAY'21
      </span>
        </Link>

      </CtaBlock>


    </Layout >
  )
}

export const query = graphql`
  query Index2020 {
        intro1: markdownRemark(frontmatter: {path: {eq: "/2020/intro1" } }) {
        html
      }

      intro2: markdownRemark(frontmatter: {path: {eq: "/2020/intro2" } }) {
        html
      }

      testimonials: allTestimonialsJson {
        edges {
        node {
        key
          name
      content
    }
  }
}

speaker_highlights: allContentfulTalk(
      filter: {node_locale: {eq: "de" }, year: {eq: 2018 } }
    ) {
        edges {
        node {
        id
          speaker {
        name
      }
      highlights {
        id
            highlights
    }
  }
}
}

images2018: allCloudinaryImage(
      filter: {context: {custom: {highlight: {eq: "true" } } } }
    ) {
        edges {
        node {
        id
          public_id
      secure_url
      scaled_image_url
      recap_image_url
          thumb_dims {
        w
            h
    }
    tags
          context {
        custom {
        highlight
              alt
      caption
    }
  }
}
}
}
}
`
