import React from 'react'

import { Row, Col } from 'reactstrap'

import logoFSF from '../media/oss/fsf.png'
import logoNumFocus from '../media/oss/numfocus.png'
import logoOSM from '../media/oss/osm.png'
import logoMilage from '../media/oss/milage.png'
import { Link } from 'gatsby'

export default class Tickets extends React.Component {
  render() {
    return (
      <Row>
        <Col lg={12}>
          <p>
            <a
              href="https://www.eventbrite.de/e/dev-day-2019-faro-pt-tickets-57505152459"
              target="_blank"
              className="btn btn-block btn--primary btn--1em"
            >
              register for DEV DAY'20 in <strong>Faro</strong>
            </a>
          </p>
          <div className="text-body-3">
            <p>
              DEV DAY'20 is an event free of charge for you but we
              kindly ask you to register here.
            </p>
          </div>
        </Col>
      </Row>
    )
  }
}
