import React from 'react'
import { Tada } from 'react-reveal'
import { Container, Row, Col } from 'reactstrap'
import Section from '../../common/Section'

export default props => (
  <Section
    section="cta cta--darken cta--tertiary text-center"
    bg={
      'https://res.cloudinary.com/turbinekreuzberg/image/upload/v1529574281/devday18/faro/33610956_2135113233412121_8141663795845005312_o.jpg'
    }
  >
    {props.title}
    <h2 className="margin-top">{props.text}</h2>
    <p className="padding-top margin-top " data-offset="90%">
      <span className="d-inline-block">{props.cta}</span>
    </p>
  </Section>
)
